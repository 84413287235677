import React from 'react'

import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>
    <h1>Hi people</h1>
    <p>I'm still working on this site, but in the meantime, you can learn more about me on <a href="https://www.linkedin.com/in/erica-pisani-5b77ab84/">LinkedIn</a></p>

  </Layout>
)

export default IndexPage
